import { Link } from "react-router-dom";

type AboutProps = {};

export const About = (props: AboutProps) => {
  const {} = props;

    return (
        <div>
            <ul className="breadcrumbs">
                <li><Link to="/">Home</Link></li>
                <li>About</li>
            </ul>
            <h4>About this site</h4>
            <p>Site is built with React/Typescript and deployed with github actions.  Local development
                environment using vite.
            </p>
            <p>Some sections of this site may use <a href="https://docs.pmnd.rs/react-three-fiber/">react-three-fiber</a>
                and other <a href="https://threejs.org/">threejs</a>/WebGL libraries -
                performance of such will be increased with a dedicated GPU in your computer.</p>
            <p>Solar data is collected in real time with python/pyModbusTCP from our Conext InsightHome
                and stored in mysql for client consumption.
            </p>
            <h4>Attributions:</h4>
            <a href="https://open-meteo.com/">Weather data by Open-Meteo.com</a>

            <p>Copyright &copy; 2024 Mark & Heather Ross</p>
        </div>
    );
};

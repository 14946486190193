import { Link } from "react-router-dom";

type HeatherMainProps = {};

export const HeatherMain = (props: HeatherMainProps) => {
  const {} = props;

  return (
    <div>
        <ul className="breadcrumbs">
            <li><Link to="/">Home</Link></li>
            <li>Heather</li>
        </ul>
      <p>You can find Heather (Stanway) Ross on facebook and instagram.</p>
    </div>
  );
};

import { Canvas } from "@react-three/fiber";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { WiggleBones } from "./WiggleBones";

const PortfolioContainer = styled.div`
`;

type MarkMainProps = {};

export const MarkMain = (props: MarkMainProps) => {
  const {} = props;

  return (
    <div>
        <ul className="breadcrumbs">
            <li><Link to="/">Home</Link></li>
            <li>Mark</li>
        </ul>
        
        <div style={{width: "800px", height: "470px"}}>
            <Canvas camera={{ position: [5, 2.5, 5] }}>
                <WiggleBones />
            </Canvas>
        </div>

        <h4>Portfolio</h4>
        <PortfolioContainer>
            <ul>
                <li>NFL Box Score/Play-by-play Shot Tracker</li>
                <li>11 St. Joseph Luxury Condominiums</li>
                <li>CTV streaming video player</li>
                <li>Shaw streaming video player</li>
            </ul>
        </PortfolioContainer>

    </div>
  );
};

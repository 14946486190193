import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { SolarAPIResponse } from "./types";

export const solarApi = createApi({
    reducerPath: "solarApi",
    baseQuery: fetchBaseQuery({baseUrl: "https://rss.mn/"}),
    endpoints: builder => ({
        getSolarInfo: builder.query<SolarAPIResponse, null>({
            query: () => `solar-data`,
        })
    }),
});

export const { useGetSolarInfoQuery } = solarApi;

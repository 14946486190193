import { Grid, OrbitControls, TransformControls, useGLTF } from "@react-three/drei";
import { useFrame, useThree } from "@react-three/fiber";
import { useEffect, useRef } from "react";
import type { Object3D, Object3DEventMap } from "three";
import { WiggleRig } from "wiggle/rig";

type RiggedObject3d = Object3D<Object3DEventMap> & {
  skeleton: unknown;
}

type WiggleBonesProps = {};

export const WiggleBones = (props: WiggleBonesProps) => {
  const {} = props;

  const { scene } = useThree();
  const { scene: stickScene } = useGLTF('/assets/models/demo-wiggle.glb');

  const wiggleRigRef = useRef<WiggleRig>();

  useEffect(() => {
      if (scene && stickScene) {
            scene.add(stickScene);
            console.log(stickScene);

            // const helper = new WiggleRigHelper({
            //     skeleton: scene.getObjectByName("Stick")?.skeleton,
            //     dotSize: 0.2,
            //     lineWidth: 0.02,
            // });
            // scene.add(helper);

            // const rootBone = scene.getObjectByName("Root");
            // const b1 = scene.getObjectByName("Bone1");
            // const b2 = scene.getObjectByName("Bone2");
            // const b3 = scene.getObjectByName("Bone3");

            // // wiggleBones.push(new WiggleBone(b1, { velocity: 10 }));
            // // wiggleBones.push(new WiggleSpring(b2, { stiffness: 100, damping: 1 }));
            // // wiggleBones.push(new WiggleSpring(b3, { stiffness: 100, damping: 1 }));
            // console.log("bones/??? ", wiggleBones)
            const riggedObject: RiggedObject3d = scene.getObjectByName("Stick") as RiggedObject3d;
            wiggleRigRef.current = new WiggleRig(riggedObject.skeleton);
      }
  }, [scene, stickScene]);

  useFrame(() => {
    if(wiggleRigRef.current) {
      wiggleRigRef.current.update();
    }
    //wiggleBones.forEach((wb) => wb.update());
  });

  return (
    <>
        <OrbitControls makeDefault />
        <ambientLight intensity={2} />
        <Grid
            position={[0, -0.01, 0]}
            args={[10.5, 10.5]}
            cellSize={0.6}
            cellThickness={1}
            cellColor='#6f6f6f'
            sectionSize={3.3}
            sectionThickness={1.5}
            sectionColor='#9d4b4b'
            fadeDistance={25}
            fadeStrength={1}
            followCamera={false}
            infiniteGrid={true}
        />
        {wiggleRigRef.current &&  <TransformControls showY={false} object={scene.getObjectByName("StickRig")} mode={"translate"} /> }
    </>
  );
};



import { useEffect } from "react";
import { useGetSolarInfoQuery } from "../services/solar";
import { Loader } from "./Loader";
import { SolarAPIResponse, SolarDevice } from "../services/types";

const decodeHtml = (html: string) => {
    const txt = document.createElement("textarea");
    txt.innerHTML = html;
    return txt.value;
  };

export const SolarDebug = () => {
    console.log("SolarDebug render");
    const { isLoading, data, refetch } = useGetSolarInfoQuery(null);

    useEffect(() => {
        const intervalId = setInterval(() => {
            refetch();
        }, 120000); // Refetch every 2 minutes

        return () => clearInterval(intervalId); // Cleanup on unmount
    }, [refetch]);
    
    // TODO Update to use the unformatted_value so we don't need this jank
    const cleanedValue = (value: string) => {
        if (value.indexOf("&deg;") !== -1) {
            return decodeHtml(value);
        }
        return value;
    };

    const renderData = (data: SolarAPIResponse) => {
        const keys = Object.keys(data.data) as SolarDevice[];

        return (
            <>
                <p>Data is collected in real time from our Conext InsightHome using python with the pyModbusTCP library.
                    This is a live and relatively real-time view of the data from all components within our system that 
                    powers our off-grid house here in Eastern Ontario.
                </p>
                <p>Data last updated: {data.timestamp} Eastern Time</p>
                {keys.map(deviceType => {
                    return (
                        <div key={deviceType}>
                            <h3>{deviceType}</h3>
                            <ul>
                                {data.data[deviceType].map(info => (
                                    <li key={info.id}>
                                        {info.name}: {cleanedValue(info.value)}
                                    </li>
                                ))}
                            </ul>
                        </div>
                    );
                })}
            </>
        );
    };

    return (
        <div>
            <h2>Solar Data</h2>
            {isLoading && (
                <Loader />
            )}
            {!isLoading && data && (
                renderData(data)
            )}
        </div>
    );
};

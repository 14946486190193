import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import { WeatherApiResponse } from "./types";

export const localConditionsApi = createApi({
    reducerPath: "localConditionsApi",
    baseQuery: fetchBaseQuery({baseUrl: "https://api.open-meteo.com/v1/"}),
    endpoints: builder => ({
        getLocalConditions: builder.query<WeatherApiResponse, null>({
            query: () => `gem?latitude=44.855452&longitude=-75.780258&current=temperature_2m,apparent_temperature,is_day,weather_code&daily=weather_code,sunrise,sunset,sunshine_duration&timezone=America%2FNew_York&forecast_days=3`,
        })
    }),
});

export const { useGetLocalConditionsQuery } = localConditionsApi;

import { useEffect, useLayoutEffect, useRef, useState } from "react";
import styled from "styled-components";
import useResizeObserver from "@react-hook/resize-observer";
import { subDays } from "date-fns";

import { HeavenlyBody } from "./HeavenlyBody";
import { useGetLocalConditionsQuery } from "../services/localConditions";

type SkyContainerProps = {
    $isDay: boolean;
    $isLoading: boolean;
}

const SkyContainer = styled.div<SkyContainerProps>`
    position: fixed;
    z-index: -1;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    ${(props) => {
        // we only want to apply the BG if we aren't loading
        if (!props.$isLoading) {
            return props.$isDay ? `
                background: linear-gradient(0deg, rgba(197,234,252,1) 0%, rgba(125,168,254,1) 100%);
            ` : `
                background: linear-gradient(0deg, #283e51 0%, #0a2342 100%);
            `;
        }
    }};
`;

const Stars = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    display: block;
    background: url(/assets/stars-background.png) repeat top center;
`;

const useSize = (target: React.RefObject<HTMLDivElement>) => {
    const [size, setSize] = useState<DOMRect>();

    useLayoutEffect(() => {
        if (target.current) {
            setSize(target.current.getBoundingClientRect());
        }
    }, [target]);

    useResizeObserver(target, (entry) => setSize(entry.contentRect));
    return size;
};

const calculateSunPosition = (currentTime: number, periodStart: number, periodEnd: number, topOffset: number) => {
    const totalDaylight = periodEnd - periodStart;
    const elapsedTime = currentTime - periodStart;
    const progress = elapsedTime / totalDaylight;
    const viewportHeight = window.innerHeight;
    const viewportWidth = window.innerWidth;
  
    const x = progress * viewportWidth;
    // we use the offset to keep the sun/moon from going off the top of the screen even partially
    const y = viewportHeight - (Math.sin(progress * Math.PI) * (viewportHeight - topOffset));
  
    return { left: x, top: y };
};

function getCurrentEasternTime() {
    const date = new Date();
    const localTime = date.getTime();
    const localOffset = date.getTimezoneOffset() * 60000; // Convert to milliseconds
    const utcTime = localTime + localOffset;

    // Create a new date object in the target timezone
    const targetDate = new Date(
        new Intl.DateTimeFormat('en-US', { timeZone: 'America/New_York' }).format(date)
    );

    const targetOffset = targetDate.getTimezoneOffset() * 60000; // Convert to milliseconds
    const targetTime = utcTime - targetOffset;

    return targetTime;
  }

export const Sky = () => {

    const { isLoading, data } = useGetLocalConditionsQuery(null);

    // we need to know how big the viewport is, including if it resizes
    const skyRef = useRef<HTMLDivElement>(null);
    const skySize = useSize(skyRef);

    // we also need to know with width and height of the icon as it can vary
    // due to its responsive styles
    const iconRef = useRef<HTMLDivElement>(null);
    const [iconDimensions, setIconDimensions] = useState({ width: 0, height: 0 });

    // where we are going to position the sun/moon
    const [position, setPosition] = useState({ left: 0, top: window.innerHeight });
    // sunrise and sunset for today
    const [sunrise, setSunrise] = useState<number>(0);
    const [sunset, setSunset] = useState<number>(0);
    // the sunrise for tomorrow (so we know length of the night)
    const [nextSunrise, setNextSunrise] = useState<number>(0);
    const [isDay, setIsDay] = useState<boolean>(false);
    const [currentTime, setCurrentTime] = useState<number>(0);

    const [loading, setLoading] = useState<boolean>(true);

    // get and save the icon dimensions
    useLayoutEffect(() => {
        if (iconRef.current) {
          const { width, height } = iconRef.current.children[0].getBoundingClientRect();
          setIconDimensions({ width, height });
        } 
    }, [iconRef]);

    useEffect(() => {
        if (!isLoading && data) {
            // we got the data, store what we need to operate
            const rise = new Date(data.daily.sunrise[0]).getTime();
            setSunrise(rise);
            const set = new Date(data.daily.sunset[0]).getTime();
            setSunset(set);
            const nextRise = new Date(data.daily.sunrise[1]).getTime();
            setNextSunrise(nextRise);
            const currently = getCurrentEasternTime();
            setCurrentTime(currently);
            
            // update the current time every ~5 seconds
            const intervalId = setInterval(() => {
                setCurrentTime(getCurrentEasternTime());
            }, 5000);

            return () => {
                clearInterval(intervalId);
            };
        }
    }, [data, iconDimensions, isLoading, skySize]);

    useEffect(() => {
        if (currentTime !== 0) {
            let periodStart = 0;
            let periodEnd = 0;
            // current time update, ensure our day night sky is correct:
            if (currentTime < sunrise) {
                // the sun has not yet risen
                setIsDay(false);
                // get yesterdays approximate sunset time
                const sunsetDate = new Date(sunset);
                const yesterdaysSunsetDate = subDays(sunsetDate, 1);
    
                periodStart = yesterdaysSunsetDate.getTime();
                periodEnd = sunrise;
            } else if (currentTime > sunrise && currentTime < sunset) {
                // we are between sunrise and sunset
                setIsDay(true);
                periodStart = sunrise;
                periodEnd = sunset;
            } else if (currentTime > sunset) {
                setIsDay(false);
                periodStart = sunset;
                periodEnd = nextSunrise;
            }
    
            // and position the sun/moon:
            const { left, top } = calculateSunPosition(currentTime, periodStart, periodEnd, iconDimensions.height / 2);
            setPosition({
                left: left - (iconDimensions.width/2),
                top: top-(iconDimensions.height/2),
            });
            setLoading(false);
        }
    }, [currentTime, iconDimensions.height, iconDimensions.width, sunrise, sunset, nextSunrise, skySize]);

    return (
        <SkyContainer ref={skyRef} $isDay={isDay} $isLoading={loading}>
            {!isDay && !loading && (
                <Stars />
            )}
            <HeavenlyBody position={position} isDay={isDay} isLoading={loading} ref={iconRef}/>
        </SkyContainer>
    );
};

import { Outlet } from "react-router-dom";
import styled from "styled-components";

import { Sky } from "./components/Sky";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: rgba(42, 42, 42, 0.8);
  border-radius: 8px;
  padding: 1rem;
  margin: 1rem;
  max-width: 600px;
`;

export const App = () => {
  console.log("App render");

  return (
    <>
      <Sky />
      <Wrapper>
        <ContentContainer>
          <h1>Mark & Heather Ross</h1>
          <Outlet />
        </ContentContainer>
      </Wrapper>
    </>
  );
};

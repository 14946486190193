import React from 'react';
import ReactDOM from 'react-dom/client';
import gsap from 'gsap';
import { useGSAP } from '@gsap/react';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { store } from './core/store.ts';

import { App } from './App.tsx';
import { Home } from './components/Home.tsx';
import { About } from './components/About.tsx';
import { Solar } from './components/Solar.tsx';
import { MarkMain } from './components/Mark/MarkMain.tsx';
import { HeatherMain } from './components/Heather/HeatherMain.tsx';

import './index.css';

gsap.registerPlugin(useGSAP);

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    errorElement: <></>,
    children: [
      {
        index: true,
        element: <Home />,
      },
      {
        path: "/mark",
        element: <MarkMain />,
      },
      {
        path: "/heather",
        element: <HeatherMain />,
      },
      {
        path: "/solar",
        element: <Solar />,
      },
      {
        path: "/about",
        element: <About />,
      }
    ],
  },
]);


ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <Provider store={store}>
      <RouterProvider router={router} />
    </Provider>
  </React.StrictMode>,
);

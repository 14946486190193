import { Link } from "react-router-dom";

import { SolarDebug } from "./SolarDebug";

type SolarProps = {};

export const Solar = (props: SolarProps) => {
  const {} = props;

  return (
    <>
        <ul className="breadcrumbs">
            <li><Link to="/">Home</Link></li>
            <li>Solar Data</li>
        </ul>
        <SolarDebug />
    </>
  );
};

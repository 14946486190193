import { forwardRef } from "react";
import styled from "styled-components";
import { IoSunny } from "react-icons/io5";
import { IoMoon } from "react-icons/io5";

const IconWrapper = styled.div<{$isDay: boolean; $isLoading: boolean, $position: any}>`
  font-size: 8rem;

   ${(props) => props.$isDay ? `
        color: #ffd700;
    ` : `
        color: #b0c4de;
    `};

  
  line-height: 0;
  position: relative;
  top:  ${(props) => props.$position.top}px;
  left: ${(props) => props.$position.left}px;
  transition: top 0.5s, left 0.5s;

  visibility: ${(props) => props.$isLoading ? `hidden` : `visible`};

  /* Adjust base font size for medium-sized smartphones */
  @media (max-width: 600px) {
    font-size: 6rem;
  }
  
  /* Adjust base font size for small smartphones */
  @media (max-width: 320px) {
    font-size: 3rem;
  }
`;

type HeavenlyBodyProps = {
  isDay: boolean;
  isLoading: boolean;
  position: {
    top: number;
    left: number;
  }
}


export const HeavenlyBody = forwardRef<HTMLDivElement, HeavenlyBodyProps>(({position, isDay, isLoading}: HeavenlyBodyProps, ref) => {
    return (
        <IconWrapper $position={position} $isDay={isDay} $isLoading={isLoading} ref={ref}>
            {isDay ? <IoSunny /> : <IoMoon />}
        </IconWrapper>
    );
});

import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";

import { solarApi } from "../services/solar";
import { localConditionsApi } from "../services/localConditions";

export const store = configureStore({
    reducer: {
        [solarApi.reducerPath]: solarApi.reducer,
        [localConditionsApi.reducerPath]: localConditionsApi.reducer,
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(solarApi.middleware, localConditionsApi.middleware),
});

setupListeners(store.dispatch);

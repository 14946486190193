import styled from "styled-components";
import { Link } from "react-router-dom";
import { PiSolarPanel } from "react-icons/pi";
import { FaInfoCircle } from "react-icons/fa";

const SectionChooser = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: row;
  justify-content: space-evenly;
`;

type HomeProps = {};

export const Home = (props: HomeProps) => {
  const {} = props;

  return (
    <>
        <SectionChooser>
            <Link to="/mark"><img src='/assets/symbol_man.png' width='100px' /></Link>
            <Link to="/heather"><img src='/assets/symbol_woman.png' width='100px' /></Link>
            <Link to="/about"><FaInfoCircle size={100}/></Link>
            <Link to="/solar"><PiSolarPanel size={100}/></Link>
        </SectionChooser>
        <p>It's really happening...after years of neglect the site is finally getting a 2024 makeover,
            and being brought up to modern standards!  Bear with me, but new updates will be added frequently over
            the next few months.
        </p>
        <p>If you came to read about us building our off-grid house, you can find 
        a link to the wordpress blog about it below:</p>
        <a href="http://rss.mn/blog">House Building Blog</a>
    </>
  );
};
